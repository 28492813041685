import React from "react"
import { Link } from "gatsby"

function BrewguideOverviewComponent({ children, header, description, linkto, linkdescription }) {
  return (
    <div className="relative text-caffeinity-brown">
      <div className="h-56 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        {children}
      </div>
      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <p className="mt-2 text-caffeinity-green-dark text-3xl font-extrabold tracking-tight sm:text-4xl">{header}</p>
          <p className="mt-3 text-lg text-gray-600">
            {description}
          </p>
          <div className="mt-8">
            <Link to={linkto} className="btn-primary inline-flex">
              {linkdescription}
            </Link>
          </div>
        </div>
      </div>

    </div>
  )
}

export default BrewguideOverviewComponent